$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;

// Small devices
@mixin sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}
