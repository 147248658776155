@media print {
  body {
    min-height: 100vh !important;
    height: auto !important;
    padding: 1px !important;
  }
  iframe {
    height: auto !important;
    overflow: visible !important;
  }

  app-navbar {
    display: none !important;
  }
}
