/* You can add global styles to this file, and also import other style files */
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import './app/core/config/mixins.scss';

* {
  font-variant-numeric: lining-nums !important;
}

*:focus {
  outline: none;
}

html {
  height: auto;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: #f9fafb;

  $c: &;

  .p-sidebar-custom {
    padding-top: 4.5rem;
    padding-bottom: 1.5rem;

    .p-sidebar-header {
      background-color: var(--neutral-0) !important;
      border-bottom: 1px solid var(--neutral-300) !important;
    }

    .p-sidebar-header,
    .p-sidebar-content {
      padding: 1rem 1.5rem !important;
    }
  }

  .p-sidebar-editmode {
    @at-root {
      #{$c} .p-sidebar-mask {
        background-color: rgba($color: #000000, $alpha: 0.1) !important;
      }
    }
    &.p-sidebar-bottom .p-sidebar-content {
      padding-bottom: 2.7rem !important;
    }
  }
}

iframe {
  border: none;
}

.p-inputgroup-with-button {
  p-dropdown {
    max-width: calc(100% - 3rem);
  }
}

.p-inputgroup-with-textfield {
  max-width: calc(100% - 3rem);

  .p-treeselect {
    width: 100%;
  }

  p-dropdown {
    min-width: 1px;
  }

  .p-input-icon-left {
    min-width: 10rem;
  }
}

.p-button.p-button-link {
  height: unset !important;
  &:disabled {
    background-color: unset !important;
  }
  .p-button-label {
    text-decoration: underline;
  }
}

.rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

/* custom calendar styling */

.cal-month-view {
  background-color: unset !important;
  height: 100%;
  mwl-calendar-month-view-header {
    height: min-content;
  }
  .cal-days {
    display: grid;
    grid-template-rows: repeat(auto-fill, 1fr);
    border: unset;
    overflow: hidden !important;
    min-height: 650px;
    & > div {
      border: 1px solid var(--neutral-300);
      &:not(:first-child) {
        border-top: unset !important;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .cal-cell-top {
    flex: unset !important;
    min-height: unset !important;
    .cal-day-number {
      float: left;
    }
  }
  .cal-cell-row:not(.cal-header) {
    height: 100%;
    background-color: var(--neutral-0);
    border: unset;
    border-radius: 4px;
    &:hover,
    &:hover .cal-today {
      background-color: var(--neutral-100) !important;
      .cal-cell:hover,
      .cal-today:hover {
        background-color: var(--neutral-200) !important;
      }
    }
    &:not(:hover) {
      .cal-today {
        background-color: var(--neutral-0);
      }
    }
    .cal-cell {
      min-height: unset;
      &:nth-child(6),
      &:nth-child(7),
      .cal-cell-holiday {
        background-color: var(--neutral-100) !important;
        &:hover {
          background-color: var(--neutral-200) !important;
        }
      }
    }
  }
  .cal-header {
    .cal-cell,
    .cal-cell:hover {
      text-align: center;
      color: var(--neutral-600);
      background-color: unset !important;
      font-weight: 400;
    }
  }
}

.cal-week-view {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  background-color: unset !important;
  border: none !important;
  mwl-calendar-week-view-header {
    z-index: 3;
    position: sticky;
  }

  .cal-day-headers {
    position: sticky;
    top: 0;
    background: var(--neutral-100);
    border: none !important;
    span {
      opacity: 1;
      font-weight: 500;
    }
    .cal-header {
      border: none !important;
      text-align: end;
      color: var(--neutral-600);
      font-weight: lighter;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
      background-color: unset !important;

      &:hover {
        background-color: unset !important;
      }
      .cal-day-label {
        display: flex;
        justify-content: end;
        align-items: center;
        font-weight: 500;
      }
    }
  }
  .cal-time-events {
    border: unset !important;

    .cal-time {
      font-weight: 400;
      font-size: 14px;
      color: var(--neutral-600);
      &:after {
        content: '';
        position: absolute;
        bottom: calc(50% - 1px);
        right: 0;
        height: 1px;
        width: 12px;
        border-top: 1px dashed var(--neutral-300);
      }
    }
  }
  .cal-time-label-column {
    background-color: var(--neutral-100) !important;
  }

  .cal-day-columns {
    padding-left: 70px;
    border: unset !important;
    border-right: 1px solid var(--neutral-300) !important;
    background-color: unset !important;
  }

  .cal-day-column {
    position: unset !important;
    background-color: var(--neutral-0);
    padding-top: 8px;
    border-top: 1px solid var(--neutral-300);
    border-bottom: 1px solid var(--neutral-300);
    overflow: hidden;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .cal-hour-odd {
      background-color: var(--neutral-0) !important;
    }

    .cal-hour-segment:hover {
      background-color: var(--neutral-0) !important;
    }

    .cal-hour:first-child {
      margin-top: 5px;
      border-top: 1px dashed var(--neutral-300);
    }

    mwl-calendar-week-view-current-time-marker {
      position: absolute;
      left: 0;
      width: 100vw;
      .cal-current-time-marker {
        display: flex;
        align-items: center;
        width: 100%;
        height: min-content;
        background: unset;
        color: var(--red-400);
        font-weight: 700;
        font-size: 14px;
        & > span {
          text-align: right;
          width: 63px;
          padding-right: 3px;
          background-color: var(--neutral-100);
          box-shadow: 0px 10px 5px var(--neutral-100), 0px -10px 5px var(--neutral-100);
        }
        &-line {
          position: relative;
          border-bottom: 1px dashed var(--red-400) !important;
          height: 1px;
          width: 100%;
          &::before {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--red-400) !important;
            position: absolute;
            top: -3px;
          }
        }
      }
    }
  }
  .cal-time-label-column {
    position: absolute;
    top: -6px;
    .cal-hour-segment {
      border: unset !important;
    }
  }
  .cal-hour-start {
    border-bottom: unset !important;
  }
}

.cal-day-view {
  .cal-day-columns {
    padding-left: 0;
    margin-left: 70px;
    background-color: var(--neutral-0);
  }
  .cal-day-column {
    border: 1px solid var(--neutral-300);
    overflow: visible;
    background-color: var(--neutral-0) !important;
  }
  .cal-hour:last-child,
  .cal-hour:last-child .cal-hour-segment:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .cal-day-column {
    background-color: unset !important;
  }
  .cal-hour-segment {
    background-color: var(--neutral-0);
  }
  .cal-time {
    position: absolute;
    padding-top: 0;
    top: -9px;
    left: -70px;
  }
  .cal-events-container {
    left: -70px;
    width: 100%;
  }
  .cal-current-time-marker {
    left: -70px;
  }
}

.cal-day-cell {
  min-width: 1px;
  /* max-height: 125px; */
  border-color: var(--neutral-300) !important;
}

.cal-day-number {
  color: var(--neutral-600) !important;
  font-size: 14px !important;
  opacity: 1 !important;
  text-align: center;
  border-radius: 50%;
  margin: 6px !important;
  width: 28px;
  height: 28px;
  line-height: 28px;

  &.cal-cell-active {
    color: var(--neutral-0) !important;
    background-color: var(--primary-400) !important;
  }
}

.cal-today {
  .cal-day-number {
    background-color: var(--neutral-200);
  }
}

.cal-out-month {
  .cal-day-number,
  .cal-day-number.cal-cell-active {
    opacity: 0.5 !important;
    color: var(--neutral-600) !important;
    background-color: var(--neutral-0) !important;
  }
}

.cal-day-columns {
  border: 1px solid var(--neutral-300) !important;
  border-radius: 4px;
  background-color: var(--neutral-0) !important;
}

.header-backlink {
  //styleName: 14/Body regular;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #747d82;
}

.map-cluster {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: var(--primary-300);
  color: var(--neutral-0);
}

.leaflet-popup {
  &-content-wrapper {
    padding: 0 !important;
    overflow: hidden;
    border-radius: 8px !important;
    border: 1px solid var(--neutral-200);
  }
  &-content {
    margin: 0 !important;
  }
}

.pixelgap {
  gap: 1px;
}

.stickyContainer {
  position: fixed;
  border-bottom: 1px solid var(--neutral-400);
  box-shadow: 0px 2px 8px 0px rgba(76, 83, 87, 0.1) !important;
  z-index: 1;
}

.advanced-repeat-dropdown {
  @include md {
    border-radius: 0 4px 4px 0;
  }

  @include lg {
    border-radius: 0 4px 4px 0;
  }
}

.advanced-input-frequency {
  @include md {
    border-radius: 4px 0 0 4px !important;
    border-right: 0 !important;
  }

  @include lg {
    border-radius: 4px 0 0 4px !important;
    border-right: 0 !important;
  }
}

.p-button.p-button-sm.lg\:p-button-md {
  @include lg {
    height: 2.5rem !important;
    padding: 0.75rem 1rem !important;
    font-size: 0.857rem !important;
  }
}

.p-panel-standalone {
  .p-panel-header {
    display: none !important;
  }

  .p-panel-content {
    background-color: white !important;
    border-top: 0px !important;
  }
}
